@import "../../Variables.scss";

.affiliate-login-page {
    max-width: 500px;
    margin: auto;

    &.autoLogin {
        text-align: center;
    }

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }
    button {
        margin-bottom: 10px;
    }

    .error {
        color: red
    }
}

.affiliate-panel {
    margin: auto;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }

    h4 {
        margin: 40px 0 20px;
    }
}

.filterModal {
    .radioContainer {
        width: 100%;
        text-align: center;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            width: 0;
            height: 0;
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        .checkmark {
            width: 100%;
            height: 25px;
            line-height: 25px;
            display: block;
            margin: auto;

            svg {
                display: none;
                color: $color-active;
                margin-left: -20px;
                margin-right: 4px;
            }
        }

        input:checked~.checkmark {
            svg {
                display: inline-block;
            }
        }
    }
}