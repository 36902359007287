@import "../../Variables.scss";

.page-about {
    .page--components {
        .component--grid {
            @media (max-width: 575px) {

                &:nth-child(2),
                &:nth-child(4) {
                    .row {
                        flex-direction: column-reverse;
                    }
                }

                img {
                    float: none !important;
                    margin: 0 auto 40px;
                    display: block;
                }

            }
        }
    }
}

.error-page {
    font-size: 7rem;

    h1 {
        font-size: 80%;
        font-weight: 600;
    }

    h2 {
        font-size: 30%;
        margin-bottom: 50px;
    }

    h3 {
        font-size: 14px;
    }
}