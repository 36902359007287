@import '../../../Variables.scss';

.sltp-bg {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.sltp-modal {
    width: 100%;
    max-width: 350px;
    max-height: 450px;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
    margin: auto;
    background: $card-bg;
    border-radius: 10px;
    padding: 10px;
    color: $card-color;

    .title {
        width: 100%;
        font-size: 18px;
        border-bottom: 1px $color-border solid;
        padding: 5px 10px;
        margin-bottom: 20px;

        span {
            float: right;
            color: $platform-color;
            cursor: pointer;
        }
    }

    form {
        padding: 0 10px;

        label {
            width: 100%;
            display: block;
            margin-bottom: 5px;
            font-weight: 600;
            margin-top: 20px;
        }

        .error {
            color: red;
        }

        .wrapper-input {
            position: relative;

            &::before {
                content: attr(data-currency);
                position: absolute;
                padding: 4px 15px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                font-size: 18px;
            }

            input {
                width: 100%;
                height: 38px;
                background-color: $color-input;
                border: 0 !important;
                margin-bottom: 20px;
                border-radius: 4px;
                padding-left: 50px;
                font-size: 22px;

                &:focus {
                    background-color: $color-input;
                    box-shadow: unset;
                }
            }
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 10px;
            left: 0;

            button {
                margin: auto;
                display: block;
            }
        }
    }
}