@import "../../../Variables.scss";

.bottom {
    width: 100%;
    min-height: 40vh;
    display: inline-block;
    overflow: auto;
    color: $second-color;

    .positions {
        .row {
            @media (min-width: 768px) {
                .col-md-2 {
                    flex: 0 0 14%;
                    max-width: 14%;
                }
            }
            &.header-table {
                >div {
                    border-top: 1px $color-border solid;
                    border-bottom: 1px $color-border solid;

                    &:first-child {
                        // padding-left: 34px;
                    }
                }
            }

            &:not(.header-table) {
                >div:first-child {
                    cursor: pointer;

                    svg {
                        margin-right: 10px;
                    }
                }

                a {
                    color: $second-color;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                .profit {
                    &.minus {
                        color: $color-sell;
                    }

                    &.plus {
                        color: $color-buy;
                    }
                }

                .sltp,
                .closePosition {
                    width: max-content;
                    color: $color-active;
                    border: 0;
                    background: transparent;
                }
            }
        }

        .positionsDataRow {
            height: 160px;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-top: 15px;
            .protected-icon {
                width: auto;
                height: 16px;
                margin: -3px 7px 0 0;
                &.not {
                    opacity: 0.2;
                }
            }
        }

        svg {
            &[data-icon="sort-up"] {
                color: $color-buy;
                vertical-align: -6px;
            }

            &[data-icon="sort-down"] {
                color: $color-sell;
                vertical-align: 0;
            }
        }
    }
}