@import "../../Variables.scss";

.contact-page {
    max-width: 1200px;
    margin: auto;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }
    .thankYou {
        width: 100%;
        text-align: center;
        margin-top: 50px;

        svg {
            display: block;
            margin: auto;
            font-size: 40px;
            color: $color-buy;
        }
    }

    button {
        margin-bottom: 10px;
    }

    .reCAPTCHA {
        margin-top: 50px;
        font-size: 12px;
    }
}