@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,800&display=swap');
@import "./Variables.scss";

body {
    &:not(.home-page) {
        padding-top: 83px;

        &.logged-in {
            padding-top: 116px;
        }
    }

    font-family: Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
    color: #3b3b3b;
    font-size: 16px;
    line-height: 30px;

    input,
    input:active,
    input:focus,
    input:focus-within,
    input:hover,
    input:visited {
        font-size: 16px !important;
        outline: none!important;
    }

    button {
        &:focus {
            outline: none;
        }
    }

    a {
        color: #717171;

        &:hover {
            color: $color-active;
        }
    }

    .thm-container {
        width: 98%;
        max-width: $size-desktop;
        margin: auto
    }

    .main-container {
        min-height: 700px;
    }

    .sec-pad {
        padding: 120px 20px;
    }

    .field {
        width: 100%;
        line-height: 28px;
        margin-bottom: 15px;
        display: inline-block;
        position: relative;

        &.phone {
            margin-bottom: 0;
        }

        textarea,
        select,
        input {
            width: 100%;
            height: 45px;
            line-height: 21px;
            border: 1px solid #d9d9d9;
            background-color: rgba(0, 0, 0, 0);
            padding: 0 20px;
            font-weight: 300;
            resize: none;
            display: block;
            border-radius: 0 !important;
            transition: 350ms;
            font-size: 16px;

            &[type="checkbox"] {
                float: left;
                width: 20px;
                height: 27px;
                margin-right: 10px;
            }
        }

        select {
            &:not([multiple]) {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-position: calc(100% - 10px) 50%;
                background-repeat: no-repeat;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
            }
        }

        textarea {
            height: 80px;
        }

        .error {
            color: red;
            display: block;
        }

        .autocomplete-dropdown-container {
            width: 100%;
            position: absolute;
            top: 73px;
            border: 1px #d9d9d9 solid;
            border-top: 0;
            z-index: 10;

            >div {
                padding-left: 5px;
            }
        }

        &.checkbox {
            input {
                width: auto;
                height: auto;
            }
        }
    }

    .tooltip {
        z-index: 4000;

        .tooltip-inner {
            background-color: #252525;
        }

        .arrow::before {
            border-top-color: #252525;
        }
    }

    .dropdown-item {
        color: #21252A !important;
        padding: 0.25rem 1.5rem !important;

        @media (max-width: 991px) {
            color: rgba(255, 255, 255, 0.5) !important;
        }

        &.active,
        &:active {
            color: unset;
            background-color: unset;
        }
    }

    .grecaptcha-badge {
        opacity: 0;
    }
}