@import "../../Variables.scss";

.main {
    min-height: 100vh;
    display: grid;
    grid-template-areas: "trade chart";
    grid-template-columns: 390px auto;
    background: $platform-bg;
    font-family: 'Source Sans Pro', sans-serif;
    border-bottom: 1px $color-border solid;
    color: $platform-color;
    
    input {
        color: $color-text-input;
    }

    .right {
        width: 100%;
        height: 100%;
        grid-area: chart;

        .chart {
            height: 60vh!important;
        }
    }

    .left {
        width: 100%;
        height: 100%;
        grid-area: trade;
        border-top: 1px $color-border solid;
        border-right: 1px $color-border solid;
    }

    .card {
        width: calc(100% - 40px);
        background: $card-bg;
        margin: 20px auto;
        border-radius: 6px;
        padding: 10px;
        overflow: hidden;
        color: $card-color;

        .title {
            line-height: 14px;
            font-size: 16px;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .wrapperContentBottom,
        .wrapperContentSidebar {
            width: 100%;
            margin: auto;

            &.wrapperContentSidebar {
                @media(min-width: 600px) {
                    min-width: 320px;
                }
            }
        }
    }



    @media(max-width: $size-landscape) {
        grid-template-areas: "trade""chart";
        grid-template-columns: 1fr;

        .left,
        .right {
            width: 100%;

            .dataWidget {
                display: none;
            }
            .market {
                max-width: calc(100vw - 40px);
            }
        }
    }



}

.selectpicker {
    height: 53px;
    background: #000;
    border: 0;
    color: #fff;
    border-radius: 0;
}