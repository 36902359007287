@import "../../Variables.scss";

.payments {
    max-width: 1200px;
    min-height: 100vh;
    margin: auto;

    h2 {
        margin-bottom: 50px;
    }

    .withdraw {
        width: 100%;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // grid-gap: 40px;

        label {
            textarea {
                height: 200px;
            }
        }
    }

    .depositPage {
        div.title {
            font-size: 2rem;
            margin-bottom: 50px;
        }

        ul {
            margin-bottom: 50px;

            .checkmark {
                height: 150px;
                line-height: 25px;
                align-items: center;
                justify-content: center;
                display: flex;
                font-weight: normal;
                background: #e5e5e5;

                @media(max-width: $size-landscape) {
                    height: 120px;

                    @media(max-width: $size-mobile) {
                        height: 100px;
                        font-size: 14px;
                    }
                }
            }

            input:checked~.checkmark {
                color: #fff;
                font-weight: 600;
            }
        }

        form {
            max-width: 600px;
            margin: auto;

            .wrapper-input {
                &:before {
                    background: #f1f1f1;
                    padding: 7px 15px;
                }

                input {
                    height: 45px;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            .footer {
                max-width: 500px;
                position: unset;

                button {
                    margin-top: 50px;
                }
            }
        }

        .icons {
            width: 70%;
            max-width: 480px;
            margin: 70px auto;
            display: block;
        }
    }
}