@import "../../../Variables.scss";


.Summary {
    width: 100%;
    font-size: 14px;
    padding: 20px;

    >div {
        align-items: center;
        text-transform: uppercase;

        @media(max-width: $size-tablet) {
            flex-direction: column;
        }

        >div {
            line-height: 22px;
            display: flex;
            justify-content: space-between;

            &:not(.actions) {
                strong {
                    margin-right: 20px;
                }
            }
            @media(max-width: $size-tablet) {
                width: 100%!important;
                margin-bottom: 10px;
            }    
        }
    }

    .actions {
        margin-top: 0;

        .btn {
            background: $color-input;
            color: #9e9e9e;
            border: 0;
            font-size: 14px !important;
            margin: 0 10px;
            padding: 6px 20px;

            svg {
                margin-right: 5px;
            }

            &.active {
                svg {
                    color: $color-active;
                }
            }

            &.deposit {
                background: $color-active;
                color: #fff;
            }
        }
    }
}