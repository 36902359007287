@import "../../../Variables.scss";


.calculator {
    width: 100%;
    font-size: 14px;

    .title {
        span {
            float: right;
        }
    }

    label {
        width: 100%;
        margin: 0;

        span {
            float: right;

            &.error {
                color: red;
            }
        }
    }

    .wrapper-amount {
        position: relative;

        input {
            width: 100%;
            height: 38px;
            background-color: $color-input;
            border: 0 !important;
            margin-bottom: 20px;
            border-radius: 4px;
            padding-left: 15px;
            font-size: 22px;

            &:focus {
                background-color: $color-input;
                box-shadow: unset;
            }
        }
    }

    .leverageOuterContainer {
        .leverageInnerContainer {
            width: 100%;
            height: 25px;
            display: flex;
            justify-content: space-between;
            border-radius: 4px;
            overflow: hidden;

            .radioContainer {
                width: 100%;
                text-align: center;
                position: relative;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background: $color-input;

                &.disable {
                    background: rgba(255, 255, 255, 0);
                    cursor: not-allowed;
                }

                input {
                    width: 0;
                    height: 0;
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }

                .checkmark {
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    display: block;
                    margin: auto;

                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }

                input:checked~.checkmark {
                    background-color: $color-active;
                    color: #fff;
                }

            }
        }
    }

    .action {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        > button {
            width: 122px;
            height: 44px;
            border-radius: 4px;
            color: #fff;
            border: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                margin-right: 10px;
                display: inline-block;
            }

            &.sell {
                background: $color-sell;
            }

            &.buy {
                background: $color-buy;
            }
        }
    }

    .you_have_no_access {
        text-align: center;
        text-transform: uppercase;
        padding-top: 15px;
        font-weight: bold;
        color: #717171;
    }
}