@import "../../../Variables.scss";

.market {
    height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
            cursor: pointer;
            font-weight: bold;
            background: $color-input;
            padding: 0 5px;
            border-radius: 6px;
            margin-top: 10px;
            text-transform: capitalize;

            &.active {
                background: $color-active;
                color: #fff;
            }
        }
    }

    .tabs-wrapper {
        position: relative;
        background: $color-input;
        border-radius: 8px;
        cursor: pointer;
        display: flex;

        &:after {
            content: '▼';
            position: absolute;
            line-height: 30px;
            top: 0;
            left: 35%;
            color: $color-text-input;
            pointer-events: none;
            font-size: 7px;
        }

        select {
            width: 100%;
            max-width: 40%;
            height: 30px;
            background: transparent;
            border: 0;
            padding: 0 10px;
            color: $color-text-input;
            font-weight: bold;
            text-transform: capitalize;
            -webkit-appearance:none;
            -moz-appearance:none;
            appearance:none;
            cursor: pointer;

            option {
                background: $card-bg;
            }

            &:focus{
                outline: none;
            }
        }

        input {
            height: 30px;
            background: transparent;
            border: none;
            text-transform: capitalize;
        }
    }
  

    .table {
        margin-top: 5px;
        height: 450px;
        overflow-y: auto;
        overflow-x: hidden;

        .row {
            display: flex;
            justify-content: space-between;
            color: $card-color;
            &:not(:last-child) {
             border-bottom: 1px $color-border solid;
            }

            .col {
                flex: 40%;
                &:first-child {
                    flex: 60%;
                }
                a {
                    padding: 5px 0;
                    color: $card-color;
                    display: block;
                }
            }
        }
    }
}