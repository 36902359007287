@import "../../Variables.scss";

.EsCapitals {
    &:root[color-mode="dark"] {
        body:not(.logged-in) {
            header {
                background: #000;
            }
        }
    }

    body {
        line-height: 1.5;
        font-weight: 300;
        color: #5e5e5e;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        h1 {
            font-size: 2.5rem !important;
            font-weight: 300;
        }

        h2 {
            font-size: 1.25rem !important;
            font-weight: 300;
            color: $thm_base_color;
        }

        h3 {
            font-size: 2.375rem;// !important;
            font-weight: 300;
//            color: $thm_base_color;
        }

        h4 {
            font-size: 1.75rem !important;
            font-weight: 300;
            color: $thm_base_color;
        }

        @media (min-width: 768px) {
            .col-md-2 {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }
        a {
            color: $thm_base_color;
        }
        .fullWidth {
            width: 100%;
            max-width: 100%;
        }
    }

    @mixin button {
        width: max-content;
        border: 1px $thm_base_color solid;
        margin: 30px 0 0;
        padding: 8px 10px;
        border-radius: 8px;
        color: $thm_base_color;
        
        &:hover {
            border-color: $thm_base_color;
            background-color: $thm_base_color;
            color: #fff;
            text-decoration: none;
            a {
                color: #fff;
            }
        }
    }

    @mixin lightButton {
        @include button();
        border-color: #fff;
        color: #fff;
        a {
            color: #fff;
        }
        &:hover {
            border-color: #fff;
            background-color: #fff;
            color: $thm_base_color;
            a {
                color: $thm_base_color;
            }
        }
    }

    .home-page {
        .header {
            background: #000;
        }

        .banner {
            max-width: 100%;
            min-height: 500px;
            height: max-content;
            overflow: hidden;
            margin-top: 86px;
            display: flex;
            align-items: center;
            background-image: url(/assets/img/EsCapitals/header.jpg);
            background-position: center;
            background-size: 100%;
            background-color: #fff;
            .item {
                text-align: center;
                color: #000;

                h2 {
                    color: #000;
                    margin: 30px 0;
                }

                a {
                    @include button();
                }
            }

            img {
                width: 60%;
                display: block;
                margin: 100px auto;
                position: sticky;
                top: 0;
            }
        }

        .section-1 {
            background-color: #f6f6f6;
            padding: 50px 20px;
            h3 {
                color: #484848;
            }
            .market-quotes-widget__symbols div:nth-child(even) {
                background-color: red;
            }
            .row {
                align-items: center;
                #chart {
                    background: white;
                    box-shadow: 1px 1px 3px 0 #bbbbbb;
                }
                a {
                    @include button();
                }
            }
        }

        .section-2 {
            background: #000;
            padding: 3rem 1rem;
            text-align: center;
            color: #fff;
            h3 {
                margin-bottom: 20px;
            }

            p {
                font-size: 1rem;
            }

            img {
                height: 60px;
                margin-bottom: 20px;
            }

            h3 {
                color: #fff;
            }

            .btn {
                @include lightButton();
            }
        }

        .payment {
            background: #000;
            font-size: 14px;
            padding: 10px 0 5px;

            .payment-gallery {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;

                svg {
                    width: 60px;
                    height: 55px;
                }

                .logo-bitcoin svg {
                    width: 80px
                }
            }
        }

        .section-4 {
            padding: 100px 0;

            ul {
                list-style: none;
                padding: 0;

                li {
                    &:before {
                        content: "+";
                        margin-right: .5rem;
                        color: $thm_base_color;
                        font-weight: 500;
                    }
                }
            }
            .btn {
                @include button();
                margin-right: 20px;
            }
        }

        .section-5 {
            background-color: #cfe4e1;
            background-image: url(/assets/img/EsCapitals/ExploreMarkets.svg);
            background-position: 110% center;
            background-repeat: no-repeat;
            background-size: 50% 70%;
            padding: 100px 0;

            h3 {
                font-size: 4.75rem;
            }

            .item-cnbs-link {
                a {
                    min-height: 30px;
                    display: block;
                    margin-bottom: .75erm;
                }
            }

            .btn {
                @include button();
                margin-right: 20px;
            }
        }

        .section-7 {
            background-color: #cfe4e1;
            border-top: 1px #fff solid;
            background-image: url(/assets/img/EsCapitals/TradersGuide.png);
            background-position: left center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            h3 {
                font-size: 4.75rem;
            }

            .row {
                min-height: 30rem;
                align-items: center;
                .btn {
                    @include button();
                }
            }
        }

        .section-8 {
            text-align: center;
            padding: 50px 0;
            h3 {
                margin-bottom: 50px;
            }

            h4 {
                font-weight: 400;
                margin: 0;
                
                strong {
                    font-size: 3rem!important;
                    line-height: 1;
//                    color: $thm_base_color;
                    margin-left: 1.5rem;
                }
            }
        }

        .section-9 {
            padding: 0 0 50px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;

            .btn {
                @include button();
                margin-bottom: 10px;
            }
        }

        .section-10 {
            text-align: center;
            padding: 50px;
            background: $thm_base_color;
            color: #fff;

            a {
                @include lightButton();
                margin-left: 10px;
            }
        }

        .footer-top {
            display: none;
        }

        footer {
            background: #000;
            color: #fff!important;
        }
    }

    .account-levels-page {
        .page-header-levels {
            max-width: 100%;
            padding: 100px 0 250px;
            background: #000000 url('/assets/img/levels-background.jpg') no-repeat scroll top center / cover;
            color: #fff;

            a {
                padding: 10px 20px;
                background: #fff;
                color: #000;
            }
        }

        .row {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: flex-start;

            > div {
                border: 1px #f0f0f0 solid;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin: 10px;

                .amount {
                    font-size: 24px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    li {
                        display: flex;

                        &:before {
                            content: '✔';
                            margin-right: 10px;
                            color: $color-buy;
                            font-size: 14px;
                        }
                    }
                }

                .button {
                    width:100%;
                    font-size: 16px;
                    text-align: center;
                    background-color: $color-sell;
                    padding: 10px;
                    display: block;
                    color: #fff;
                }
            }
        }
    }

    .page-header-stocks {
        max-width: 100%;
        margin: 0;
        padding: 100px 0 250px;
        background: #000000 url('/assets/img/EsCapitals/homepage-banner.jpg') no-repeat scroll top center / cover;
        color: #fff;

        a {
            padding: 10px 20px;
            background: #fff;
            color: #000;
        }
    }

    .page-header-trading-view {
        max-width: 100%;
        margin: 0;
        padding: 60px 0 70px;
        background: url('/assets/img/EsCapitals/homepage-banner.jpg') no-repeat scroll top center / cover;
        color: #000;
        margin-bottom: 100px;
    }

    .webtrader-page {
        font-size: 14px;
        line-height: normal;

        .page-header-webtrader {
            margin-bottom: 100px;
        }
    }

    h2 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .mytop {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #e1e5e9;
        padding: 20px;

        h3 {
            color: #1cbb4b;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 20px;
        }

        ul {
            width: 100%;
            margin: auto 0;
            padding: 0 0 0 20px;
            font-size: 16px;
            list-style-type: none;

            li {
                margin-bottom: 10px;
                font-size: 16px;

                &:before {
                    color: #0fa937;
                    content: "✔";
                    font: 15px/1;
                    margin-left: -20px;
                    margin-right: 5px;
                }
            }
        }
    }

    hr {
        background: #151822 !important;
    }
}