@import "../../Variables.scss";

.login-page,
.autoLogin-page {
    .login {
        max-width: 500px !important;
        margin: auto;

        &.autoLogin {
            text-align: center;

            #loginButton {
                visibility: hidden;
            }
        }

        h1 {
            font-weight: bold;
            margin-bottom: 50px;
        }

        button {
            margin-bottom: 10px;
        }

        .error {
            color: red
        }

        .spinner {
            font-size: 30px;
            margin-top: 30px;
            color: $color-active;
        }

        .reCAPTCHA {
            margin-top: 50px;
            font-size: 12px;
        }
    }
}