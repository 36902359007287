@import '../../../Variables.scss';

.positionInfo-bg {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.positionInfo-modal {
    width: 100%;
    max-width: 400px;
    max-height: 450px;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
    margin: auto;
    background: $card-bg;
    border-radius: 10px;
    padding: 10px;
    overflow-x: hidden;
    color: $card-color;

    .title {
        width: 100%;
        font-size: 18px;
        border-bottom: 1px $color-border solid;
        padding: 5px 10px;
        margin-bottom: 20px;

        span {
            float: right;
            color: $platform-color;
            cursor: pointer;
        }
    }

    .profit {
        &.minus {
            color: $color-sell;
        }

        &.plus {
            color: $color-buy;
        }
    }
}