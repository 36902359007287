@import '../../../Variables.scss';

.deposit-bg {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.deposit-modal {
    width: 100%;
    max-width: 350px;
    max-height: 450px;
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
    margin: auto;
    background: $card-bg;
    border-radius: 10px;
    padding: 10px;
    color: $card-color;

    >.title {
        width: 100%;
        font-size: 18px;
        border-bottom: 1px $color-border solid;
        padding: 5px 10px;
        margin-bottom: 20px;

        span {
            float: right;
            color: $platform-color;
            cursor: pointer;
        }
    }

    ul {
        flex-wrap: wrap;

        li {
            max-width: 50%;
            flex: 1 0 50%;
            margin-bottom: 5px;
        }
    }
}

.depositPage {
    ul {
        li {
            max-width: 185px;
            cursor: pointer;
            font-weight: bold;
            background: $color-input;
            padding: 0 10px;
            border-radius: 6px;
            margin: 10px 10px 0 0;
            font-weight: normal;
            font-size: 14px;
            flex: 1 0 25%;

            &.active {
                background: $color-active;
                color: #000;
            }
        }
    }
}

.deposit-modal,
.depositPage {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        justify-content: left;
    }

    form {
        padding: 0 10px;

        .radioContainer {
            width: 100%;
            text-align: center;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-bottom: 0;

            input {
                width: 0;
                height: 0;
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            .checkmark {
                width: 95%;
                height: 25px;
                line-height: 25px;
                display: block;
                margin: auto;
                background: $color-input;
                border-radius: 4px;
                font-weight: 100;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }

            input:checked~.checkmark {
                background-color: $color-active;
                color: $platform-bg;
                font-weight: 600;
            }

        }

        label {
            width: 100%;
            display: block;
            margin-bottom: 5px;
            font-weight: 600;
        }

        .wrapper-input {
            position: relative;

            &::before {
                content: attr(data-currency);
                position: absolute;
                top: 38px;
                padding: 4px 15px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                font-size: 18px;
            }

            input {
                width: 100%;
                height: 38px;
                line-height: normal;
                background-color: $color-input;
                border: 0 !important;
                margin-top: 10px;
                border-radius: 4px;
                padding-left: 50px;
                font-size: 22px;

                &::placeholder {
                    font-size: 14px;
                }

                &:focus {
                    background-color: $color-input;
                    box-shadow: unset;
                }
            }
        }

        .error {
            color: red
        }

        .spinner {
            margin: 50px auto;
            display: block;
            color: $color-active;
        }

        .thankYou {
            width: 100%;
            text-align: center;
            margin-top: 50px;

            svg {
                display: block;
                margin: auto;
                font-size: 40px;
                color: $color-buy;
            }
        }

        .footer {
            width: 100%;
            position: absolute;
            bottom: 10px;
            left: 0;

            button {
                margin: auto;
                display: block;
            }
        }
    }

    #cashier {
        width: 0;
        height: 0;
        overflow: hidden;
    }
}