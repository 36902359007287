@import "../../Variables.scss";
$main-color: #282143;
$secondary-color: #eb5563;

.EurBlockchains {
        &:root[color-mode="dark"] {
            body:not(.logged-in) {
                header {
                    background: $main-color;
                }
            }
        }
        .header.stricky-fixed {
            border: 0;
        }
    
        body {
            line-height: 1.5;
            font-size: 15px;
            font-weight: 300;
            color: #5e5e5e;
            font-family: 'Roboto', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
    
            h1 {
                font-size: 2.5rem !important;
                font-weight: 300;
            }
    
            h2 {
                font-size: 1.5rem !important;
                font-weight: 300;
                color: $thm_base_color;
            }
    
            h3 {
                font-size: 2.375rem;// !important;
                font-weight: 300;
    //            color: $thm_base_color;
            }
    
            h4 {
                font-size: 1.75rem !important;
                font-weight: 300;
                color: $thm_base_color;
            }
    
            @media (min-width: 768px) {
                .col-md-2 {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
            }
    
            .fullWidth {
                width: 100%;
                max-width: 100%;
            }
    
            .btn-primary {
                color: #fff;
                background-color: $secondary-color;
                border-color: $secondary-color;
                border-radius: 0;
                a {color: #fff}
            }
    
            ol, ul, dl {
                padding-left: 16px;
                line-height: 35px;
            }
        }
    
        @mixin button ($background: $secondary-color, $color:#fff,  $hover: $secondary-color) {
            width: max-content;
            border: 1px $background solid;
            margin: 30px 0 0;
            padding: 10px 15px;
            border-radius: 0;
            color: $color;
            background-color: $background;
            
            &:hover {
                border-color: $hover;
                background-color: $hover;
                text-decoration: none;
            }
        }
    
        @mixin lightButton {
            @include button();
            border-color: #fff;
            color: #fff;
            a {
                color: #fff;
            }
            &:hover {
                border-color: #fff;
                background-color: #fff;
                color: $thm_base_color;
                a {
                    color: $thm_base_color;
                }
            }
        }
    
        .home-page {
            .header {
                background: $main-color;
            }
    
            .homehero {
                max-width: 100%;
                min-height: 500px;
                height: max-content;
                overflow: hidden;
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background: linear-gradient(135deg, $main-color 60%, $secondary-color 100%);
                position: relative;
    
                .row {
                    >div {
                        display: flex;
                    }
                }
                .item {
                    text-align: center;
                    color: #fff!important;
                    margin: auto 0;
                    padding: 30px 0;
                    h2 {
                        margin: 30px 0;
                        color: #fff!important;
                    }
    
                    a {
                        @include button();
                    }
                }
    
                .financefigures  {
                    width: 100vw;
                    max-width: 100vw;
                    margin: 0 -15px;
                    background-color: rgba(255,255,255,0.5);
                    color: #000;
                    padding: 20px;
                    line-height: normal;
                    font-size: 14px;
    
                    .row {
                        width: max-content;
                        margin: auto;
                        text-align: center;
    
                        > div {
                            position: relative;
                            padding: 0 20px;
                            flex: unset;
                            max-width: min-content;
                            &:not(:last-child) {
                                &:after {
                                    content: '/';
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    color: $main-color;
                                    font-size: 40px;
                                    margin: auto;
                                    height: min-content;
                                }
                            }
                        }
                        p {
                            margin: 0;
    
                            strong {
                                margin-bottom: 5px;
                                font-size: 16px;
                                display: block;
                            }
                        }
                    }
                }
            }
    
            .proofpoints {
                max-width: unset;
                padding: 50px 20px;
                text-align: center;
                background-image: -webkit-linear-gradient(328deg,#fcfbfa 53%,#f0ebe3 53%);
                background-image: linear-gradient(122deg,#fcfbfa 53%,#f0ebe3 53%);
                .proofpoints__inner {
                    max-width: max-content;
                    ul {
                        text-align: left;
                    }
                }
            }
    
            #ticker {
                background-color: #f0ebe3;
            }
    
            .technology__edge {
                background: #00111a;
                padding: 50px 20px;
                color: #fff;
                .row {
                    >div {
                        display: flex;
                        align-items: center;
                    }
                }
            }
    
            .live__widget {
                text-align: center;
                padding: 50px 20px;
    
                #chart {
                    margin-top: 50px;
                }
            }
    
            .popular__markets {
                padding: 50px 20px;
                text-align: center;
                color: #000;
                font-weight: bold;
                h3 {
                    margin-bottom: 20px;
                }
    
                img {
                    height: 60px;
                    margin-bottom: 20px;
                }
            }
    
            .section-5 {
                background-color: #f0ebe3;
                background-image: url(/assets/img/Exitoso/ExploreMarkets.svg);
                background-position: 110% center;
                background-repeat: no-repeat;
                background-size: 50% 70%;
                padding: 50px 20px 90px;
    
                h3 {
                    margin-bottom: 30px;
                }
    
                .btn {
                    margin-top: 30px;
                }
    
            }
    
            .call_to_action {
                text-align: center;
                padding: 50px;
                background: $main-color;
                color: #fff;
                position: relative;
    
                h3 {
    
                }
                >div {
                    z-index: 1;
                    position: relative;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    top: -20px;
                    right: 0;
                    background-image: -webkit-linear-gradient(322deg,transparent 58%,$main-color 58%);
                    background-image: linear-gradient(128deg,transparent 58%,$main-color 58%);
                }
            }
    
            .footer-top {
                display: none;
            }
    
            footer {
                background: #000;
                color: #fff!important;
            }
        }
    
        .account-levels-page {
            .page-header-levels {
                max-width: 100%;
                padding: 100px 0 250px;
                background: #000000 url('/assets/img/levels-background.jpg') no-repeat scroll top center / cover;
                color: #fff;
    
                a {
                    padding: 10px 20px;
                    background: #fff;
                    color: #000;
                }
            }
    
            .row {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                align-items: flex-start;
    
                > div {
                    border: 1px #f0f0f0 solid;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    margin: 10px;
    
                    .amount {
                        font-size: 24px;
                    }
    
                    ul {
                        list-style: none;
                        padding: 0;
                        li {
                            display: flex;
    
                            &:before {
                                content: '✔';
                                margin-right: 10px;
                                color: $color-buy;
                                font-size: 14px;
                            }
                        }
                    }
    
                    .button {
                        width:100%;
                        font-size: 16px;
                        text-align: center;
                        background-color: $color-sell;
                        padding: 10px;
                        display: block;
                        color: #fff;
                    }
                }
            }
        }
    
        .page-header-stocks {
            max-width: 100%;
            margin: 0;
            padding: 100px 0 250px;
            background-color: #000;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-attachment: scroll;
            color: #fff;
            position: relative;
    
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
            }
    
            > div {
                position: relative;
                z-index: 10;
            }
    
            a {
                padding: 10px 20px;
                background: #fff;
                color: #000;
            }
        }
    
        .page-header-trading-view {
            max-width: 100%;
            margin: 0;
            padding: 60px 0 70px;
            background: url('/assets/img/Exitoso/homepage-banner.jpg') no-repeat scroll top center / cover;
            color: #000;
            margin-bottom: 100px;
        }
    
        .webtrader-page {
            font-size: 14px;
            line-height: normal;
    
            .page-header-webtrader {
                margin-bottom: 100px;
            }
        }
    
        h2 {
            font-size: 30px;
            margin-bottom: 10px;
        }
    
        .mytop {
            width: 100%;
            background-color: #ffffff;
            border: 1px solid #e1e5e9;
            padding: 20px;
    
            h3 {
                color: #1cbb4b;
                font-weight: 700;
                font-size: 24px;
                margin-bottom: 20px;
            }
    
            ul {
                width: 100%;
                margin: auto 0;
                padding: 0 0 0 20px;
                font-size: 16px;
                list-style-type: none;
    
                li {
                    margin-bottom: 10px;
                    font-size: 16px;
    
                    &:before {
                        color: #0fa937;
                        content: "✔";
                        font: 15px/1;
                        margin-left: -20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    
        hr {
            background: #151822 !important;
        }
    }