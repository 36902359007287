@import "../../Variables.scss";

.referrals {
    
    .header-referrals {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        .link {
            width: 365px;
            padding: 5px 10px;
            background-color: antiquewhite;
            border: 2px #cab59b dotted;
            font-size: 14px !important;
            display: block;
        }
    }

    .table {
        margin-bottom: 0;
    }
}
