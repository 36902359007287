@import "../../Variables.scss";

.forgot-password {
    max-width: 500px !important;
    margin: auto;

    h1 {
        font-weight: bold;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 50px;
    }

    button {
        margin-bottom: 10px;
    }

    .error {
        color: red
    }
    
    .reCAPTCHA {
        margin-top: 50px;
        font-size: 12px;
    }
}