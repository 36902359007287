.footer-top {
    width: 100%;
    background: #f1f1f2;

    .payment-icons {
        max-width: 100%;
    }
}

footer.site-footer {
    background: #1a1f27;
    color: #fff!important;

    h3 {
        color :#fff!important;
    }

    a {
        color: #fff!important;
    }

    ul {
        padding: 0;
        list-style: none;
    }
}

.footer-bottom {
    border-top: 1px #fff solid
}