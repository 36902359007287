@import '../../Variables.scss';

.topHeader {
    width: 100%;
    height: 30px;
    background: $topHeader-bg;
    color: $platform-color;
    display: flex;
    > div {
        display: flex;
        justify-content: flex-end;
        span {
            margin: 0 20px;
            font-size: 14px;
            a {
                color: $platform-color;
                text-decoration: underline;
            }
            &.link {
                color: $color-buy;
                cursor: pointer;
            }
        }

        @media (max-width: $size-landscape) {
            justify-content: flex-start;
            span {
                margin: 0 10px;
                font-size: 12px;
            }
            .verified,
            .level {
                display: none;
            }
        }
    }
}
.header {
    background: $platform-bg;
    // -webkit-transition: background 0.2s;
    // transition: background 0.2s;

    &.stricky-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        border-bottom: 1px $color-border solid;

        .thm-container {
            box-shadow: none;
        }

        ul.navigation-box>li {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        #side-navbar-nav {
            max-width: max-content;
        }
    }

    .navbar-nav {
        .login-botton {
            .btn {
                background: #fff;
                color: #000;
            }
        }

        .btn-link {
            color: $platform-color !important;
        }

        .wrapperModes {
            display: flex;
            justify-content: center;

            .mode {
                -webkit-appearance: none;
                position: relative;
                width: 50px;
                height: 25px;
                background-image: url('/assets/img/darkMode.png');
                background-position: center;
                background-size: 80%;
                background-repeat: no-repeat;
                border-radius: 50px;
                outline: none;
                transition: background-image .90s;
                align-self: center;
                box-shadow: 0px 0px 2px 1px #576e8c;
                cursor: pointer;
                
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: navy;
                    border-radius: 50px;
                    // transition: all .9s;
                    background-color: #ECF0F3;
                }
                
                &:checked {
                    background-image: url('/assets/img/lightMode.png');
                    box-shadow: 0px 0px 2px 1px #dedede;

                    &:before {
                        transform: translate(100%);
                        // transition: all .9s;
                        background-color: #F7CA33;
                    }
                }
            }

            .dropdown {
                display: flex;
                align-items: center;
                margin-left: 20px;

                .flag {
                    width: 30px;
                    margin-right: 5px;
                }

                a {
                    padding: 0;
                    display: flex;
                    align-items: center;
                }
            }
        }

        @media(max-width: 991px) {
            .dropdown-menu {
                background: transparent;
                border: 0;

                a {

                    &:focus,
                    &:hover {
                        background: transparent;
                        color: #fff;
                    }

                }

                .dropdown-divider {
                    display: none;
                }
            }
        }
    }

    .logo {
        width: 180px;
        height: 40px;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .thm-container {
        background: transparent;
        position: relative;
    }
}

.home-page {
    .header.transparent {
        background: transparent;
        border: 0;
    }
}

.platform {
    .thm-container {
        max-width: 100%;
    }
}