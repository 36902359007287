@import "../../Variables.scss";

.my-account {
    max-width: 1200px;
    margin: auto;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }

    button {
        margin-bottom: 10px;
    }

    .tabs {
        list-style: none;
        display: flex;
        padding: 15px 0;
        margin: 0 0 35px;

        li {
            margin-right: 20px;
            font-size: 16px;

            &.active {
                a {
                    font-weight: bold;
                    color: $color-active
                }
            }
        }
    }

    .documents {

        .card {
            .card-body {
                .card-title {
                    color: $color-active;
                }
            }
            .card-footer {
                .status {
                    display: block;

                    &.Processing {
                        color: blue
                    }
                    &.Approved {
                        color: green
                    }
                    &.Error,
                    &.Rejected {
                        color: red
                    }
                }
            }
        }
    }

    .changePassword {
        max-width: 400px;
    }

    .userInformation {
        h2 {
            margin-bottom: 40px;
            text-align: center;
        }

        div {
            margin-bottom: 20px;

            strong {
                display: block;
            }

            &.avatar {
                svg {
                    font-size: 100px;
                    margin: auto;
                    display: block;
                }
            }
        }
    }
}