// @import url(https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i);

$size-mobile: 500px;
$size-tablet: 600px;
$size-landscape: 900px;
$size-desktop: 1200px;
$thm_base_color: var(--base);
$thm_black: #171717;
$thm_gray: #F0F0F0;
$second-color: #576f8d;
$color-active: $thm_base_color;
$color-buy:#51a39a;
$color-sell: #de5e56;

:root[color-mode="dark"] {
    --platform-color: #fff;
    --platform-bg: #141721;
    --color-input: rgba(255, 255, 255, 0.03);
    --color-text-input: 255,255,255;
    --color-border: #373c4d;
    --card-bg: #1b212e;
    --card-color: #fff;
    --topHeader-color: #1c202b;
}

:root[color-mode="light"] {
    --platform-color: #000;
    --platform-bg: #f8fafb;
    --color-input: rgba(115, 115, 115, 0.1);
    --color-text-input: 0,0,0;
    --color-border: #d8d9d9;
    --card-bg: #fefefe;
    --card-color: #000;
    --topHeader-color: #ececec;
}

$platform-color: var(--platform-color);
$color-input: var(--color-input);
$color-text-input: rgba(var(--color-text-input),1);
$platform-bg: var(--platform-bg);
$color-border: var(--color-border);
$card-bg: var(--card-bg);
$card-color: var(--card-color);
$topHeader-bg: var(--topHeader-color);

@mixin activeGradient {
    background: $color-active;
    background: -moz-linear-gradient(left, $color-active 0%, #fa4b1a 100%);
    background: -webkit-linear-gradient(left, $color-active 0%, #fa4b1a 100%);
    background: linear-gradient(to right, $color-active 0%, #fa4b1a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$color-active, endColorstr='#fa4b1a', GradientType=1);
}

@mixin activeTextGradient {
    @include activeGradient();
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $thm_base_color;
    border-color: $thm_base_color;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: $color-input;
    border-color: $color-input;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #a5a5a5;
    border-color: #a5a5a5;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }
}


::-webkit-scrollbar {
    visibility: visible;
    width: 6px !important;
    height: 6px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(var(--color-text-input),0.3);
}

body {
    &::-webkit-scrollbar-track {
        border-radius: 0 !important;
    }
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background: $color-input;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none !important;
    box-shadow: none;
}

.PhoneInput {
    display: block;
    position: relative;

    .PhoneInputCountry {
        position: absolute;
        top: 0;
        left: 0;

        .PhoneInputCountryIcon--border {
            background-color: transparent;
            box-shadow: none;

            .PhoneInputCountryIconImg {
                float: left;
                margin: 7px 0;
            }
        }

        .PhoneInputCountryIcon {
            margin: 8px 0 0 9px;
        }

        .PhoneInputCountrySelectArrow {
            margin-top: 17px;
            margin-left: 9px;
        }
    }

    .PhoneInputInput {
        padding-left: 55px;
    }
}