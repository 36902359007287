@import "../../Variables.scss";

.register {
    max-width: 600px !important;
    margin: auto;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }

    button {
        margin-bottom: 10px;
    }

    .error {
        color: red
    }

    .reCAPTCHA {
        margin-top: 50px;
        font-size: 12px;
    }
}