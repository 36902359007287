@import "../../Variables.scss";

.transactions {
    max-width: 1200px;
    margin: auto;

    h1 {
        font-weight: bold;
        margin-bottom: 50px;
    }

    button {
        margin-bottom: 10px;
    }

    .tabs {
        margin-bottom: 20px;

        a {
            display: inline-block;
            padding-right: 20px;

            &.active {
                color: $color-active;
            }
        }
    }

    .wrapper-table {
        position: relative;

        .swipe-icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.7);
            padding-top: 100px;
            text-align: center;

            img {
                max-width: 70px;
            }
        }

        table {
            width: 1000px;
        }
    }

    //992px
}